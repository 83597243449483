import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import type { FormSchemaUpdatedDataState } from 'react-formatge/dist/cjs/types'
import type { FormFieldType, OnFormSubmit } from 'react-formatge/dist/esm/types'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { FormWrapperCustom } from '../components'

export interface DiscountCodeFormType {
  code: string
}

interface IDiscountCodeProps extends StackProps {
  onFormSubmit?: OnFormSubmit<DiscountCodeFormType>
  error?: any
}

export interface DiscountCodeFormOverrides {
  discountFormName?: string
  discountFormBtnLabel?: string
}

const DiscountCodeForm: FC<IDiscountCodeProps> = ({ error, onFormSubmit, ...props }) => {
  const overrides = useOverridesContext<keyof DiscountCodeFormOverrides>()
  const handleOnFormSubmit = async (
    updatedData: Partial<DiscountCodeFormType>,
    formData: FormSchemaUpdatedDataState<DiscountCodeFormType>
  ) => {
    await onFormSubmit?.({ code: updatedData.code || '' })
    // resets fields after submit
    formData.code.value = ''
  }

  const inputFields: FormFieldType<DiscountCodeFormType>[] = [
    {
      name: 'code',
      componentType: 'input',
      label: overrideText('vill du lägga till en rabattkod?', overrides?.discountFormName),
      placeholder: 'Rabattkod',
      initialValue: '',
      validation: { required: true }
    }
  ]

  const buttonProps = {
    children: overrideText('Lägg till', overrides?.discountFormBtnLabel)
  }

  return (
    <FormWrapperCustom
      onSubmitCb={handleOnFormSubmit}
      {...{ inputFields, buttonProps, error }}
      {...props}
    />
  )
}

export default DiscountCodeForm
