import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import type { FormFieldType, FormUpdatePayload, OnFormSubmit } from 'react-formatge/dist/esm/types'
import { formValidationRgx } from 'react-formatge'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { rgxExtension } from '../react-formatge-extensions/rgxExtension'
import { FormWrapperCustom } from '../components'

export interface LoginFormType {
  email: string
  password: string
}

interface LoginFormProps extends StackProps {
  onFormSubmit?: OnFormSubmit<Partial<LoginFormType>>
  error?: string
  onUpdate?: (payload: FormUpdatePayload<LoginFormType>) => void | Promise<void>
}

export interface LoginFormOverrides {
  loginFormEmail?: string
  loginFormPwd?: string
  loginFormBtnLabel?: string
}

const LoginForm: FC<LoginFormProps> = ({ onUpdate, error, onFormSubmit, ...props }) => {
  const overrides = useOverridesContext<keyof LoginFormOverrides>()

  const inputFields: FormFieldType<LoginFormType>[] = [
    {
      name: 'email',
      componentType: 'input',
      label: overrideText('E-postadress', overrides?.loginFormEmail),
      placeholder: overrideText('E-postadress', overrides?.loginFormEmail),
      initialValue: '',
      validation: {
        required: true,
        validator: {
          regEx: formValidationRgx.email,
          error: 'Invalid email format.'
        }
      }
    },
    {
      name: 'password',
      componentType: 'input',
      label: overrideText('Lösenord', overrides?.loginFormPwd),
      placeholder: overrideText('Lösenord', overrides?.loginFormPwd),
      type: 'password',
      initialValue: '',
      validation: {
        required: true,
        validator: {
          regEx: rgxExtension.password,
          error: 'Wrong password format'
        }
      }
    }
  ]

  const buttonProps = {
    children: overrideText('Logga in', overrides?.loginFormBtnLabel)
  }

  const handleOnFormSubmit = async (updatedData: Partial<LoginFormType>) => {
    if (!onFormSubmit) return
    await onFormSubmit(updatedData)
  }

  return (
    <FormWrapperCustom<LoginFormType>
      onSubmitCb={handleOnFormSubmit}
      {...{ inputFields, buttonProps, error, onUpdate }}
      {...props}
    />
  )
}

export default LoginForm
