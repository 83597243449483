import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import type {
  FormFieldType,
  FormSchemaUpdatedDataState,
  OnFormSubmit
} from 'react-formatge/dist/esm/types'
import { DatePickerComponent } from 'react-formatge'
import { format } from 'date-fns'
import type { CustomerDTO, CustomerUpdateDTO, Option } from 'ecosystem'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import OptionSelect from '../react-formatge-extensions/OptionSelect'
import { FormWrapperCustom } from '../components'

export type ProfileFormType = Omit<CustomerUpdateDTO, 'addresses' | 'phones'>

interface ProfileFormProps extends StackProps {
  data?: Option<CustomerDTO>
  onFormSubmit?: OnFormSubmit<Partial<ProfileFormType>>
  error?: string
}

export interface ProfileFormOverrides {
  profileFormFirstName?: string
  profileFormMiddleName?: string
  profileFormLastName?: string
  profileFormOrganizationName?: string
  profileFormGender?: string
  profileFormDateOfBirth?: string
  profileFormBtnLabel?: string
}

const ProfileForm: FC<ProfileFormProps> = ({ data, error, onFormSubmit, ...props }) => {
  const overrides = useOverridesContext<keyof ProfileFormOverrides>()
  const inputFields: FormFieldType<ProfileFormType>[] = [
    {
      name: 'firstName',
      componentType: 'input',
      label: overrideText('Förnamn', overrides?.profileFormFirstName),
      initialValue: data?.firstName || ''
    },
    {
      name: 'middleName',
      componentType: 'input',
      label: overrideText('Mellannamn', overrides?.profileFormMiddleName),
      initialValue: data?.middleName || ''
    },
    {
      name: 'lastName',
      componentType: 'input',
      label: overrideText('Efternamn', overrides?.profileFormLastName),
      initialValue: data?.lastName || ''
    },
    {
      name: 'organizationName',
      componentType: 'input',
      label: overrideText('Organisation', overrides?.profileFormOrganizationName),
      initialValue: data?.organizationName || ''
    },
    {
      name: 'gender',
      label: overrideText('Kön', overrides?.profileFormGender),
      componentType: 'component',
      initialValue: data?.gender || 'UNDEFINED',
      component: (
        <OptionSelect
          items={[
            { label: 'Male', value: 'MALE' },
            { label: 'Female', value: 'FEMALE' },
            { label: 'Undefined', value: 'UNDEFINED' }
          ]}
        />
      )
    },
    {
      name: 'dateOfBirth',
      label: overrideText('Födelsedatum', overrides?.profileFormDateOfBirth),
      componentType: 'component',
      initialValue: data?.dateOfBirth ? new Date(data.dateOfBirth) : null,
      component: (
        <DatePickerComponent
          title={overrideText('Födelsedatum', overrides?.profileFormDateOfBirth)}
        />
      )
    }
  ]

  const buttonProps = {
    children: overrideText('Updatera', overrides?.profileFormBtnLabel)
  }

  const handleOnFormSubmit = async (
    updatedData: Partial<ProfileFormType>,
    formData: FormSchemaUpdatedDataState<ProfileFormType>
  ) => {
    if (!onFormSubmit) return
    const dateOfBirth = formData.dateOfBirth.value as Option<Date>

    await onFormSubmit({
      ...updatedData,
      dateOfBirth: dateOfBirth ? format(dateOfBirth, 'yyyy-MM-dd') : null
    })
  }

  return (
    <FormWrapperCustom<ProfileFormType>
      onSubmitCb={handleOnFormSubmit}
      {...{ inputFields, buttonProps, error }}
      {...props}
    />
  )
}

export default ProfileForm
