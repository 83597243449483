import type { SelectProps, StackProps } from '@chakra-ui/react'
import { FormLabel, Select, VStack } from '@chakra-ui/react'
import type { FC } from 'react'

export interface BaseOption<Value = string> {
  label: string
  value: Value
}

export interface IOption {
  label: string
  value: string | null
  color?: string
}

export interface OptionSelectionProps extends SelectProps {
  options: IOption[]
  label?: string
  alignItems?: string
  wrapperProps?: StackProps
}

const OptionSelection: FC<OptionSelectionProps> = ({
  options,
  label,
  alignItems,
  wrapperProps,
  ...selectProps
}) => {
  return (
    <VStack alignItems={alignItems} w="100%" {...wrapperProps}>
      {label ? <FormLabel m={0}>{label}</FormLabel> : null}

      <Select borderRadius="sm" variant="filled" {...selectProps}>
        {options.map(({ value, label: l }) => (
          <option key={value} value={value ?? ''}>
            {l}
          </option>
        ))}
      </Select>
    </VStack>
  )
}

export default OptionSelection
