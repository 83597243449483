import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import type { FormFieldType, OnFormSubmit } from 'react-formatge/dist/esm/types'
import { formValidationRgx } from 'react-formatge'
import type { FormSchemaUpdatedDataState } from 'react-formatge/dist/cjs/types'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { FormWrapperCustom } from '../components'

export interface MagicLinkFormType {
  email: string
}

interface MagicLinkFormProps extends StackProps {
  data?: MagicLinkFormType
  onFormSubmit?: OnFormSubmit<MagicLinkFormType>
  error?: string
}

export interface MagicLinkFormOverrides {
  magicLinkFormEmail?: string
  magicLinkFormBtnLabel?: string
}

const MagicLinkForm: FC<MagicLinkFormProps> = ({ data, error, onFormSubmit, ...props }) => {
  const overrides = useOverridesContext<keyof MagicLinkFormOverrides>()
  const inputFields: FormFieldType<MagicLinkFormType>[] = [
    {
      name: 'email',
      componentType: 'input',
      label: overrideText('E-postadress', overrides?.magicLinkFormEmail),
      placeholder: overrideText('E-postadress', overrides?.magicLinkFormEmail),
      initialValue: data?.email || '',
      validation: {
        required: true,
        validator: {
          regEx: formValidationRgx.email,
          error: 'Invalid email format.'
        }
      }
    }
  ]

  const buttonProps = {
    children: overrideText('Skicka', overrides?.magicLinkFormBtnLabel)
  }

  const handleOnFormSubmit = async (
    _: Partial<MagicLinkFormType>,
    formData: FormSchemaUpdatedDataState<MagicLinkFormType>
  ) => {
    if (!onFormSubmit) return

    const email = formData.email.value as string

    if (email) {
      await onFormSubmit({ email })
      formData.email.value = ''
    }
  }

  return (
    <FormWrapperCustom<MagicLinkFormType>
      onSubmitCb={handleOnFormSubmit}
      {...{ inputFields, buttonProps, error }}
      {...props}
    />
  )
}

export default MagicLinkForm
