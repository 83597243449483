import { FormWrapper } from 'react-formatge'
import type { FormWrapperProps } from 'react-formatge/dist/esm/types'

type FormWrapperCustomProps<T> = FormWrapperProps<T>

const FormWrapperCustom = <T,>({ ...props }: FormWrapperCustomProps<T>) => {
  return (
    <FormWrapper<T>
      py={2}
      sx={{
        '.chakra-popover__content': {
          minW: 350,
          minH: 'auto',
          bg: 'background.default'
        },
        label: {
          p: 0,
          fontSize: 'sm'
        },
        select: {
          h: '40px',
          borderRadius: 'sm',
          borderColor: 'primary',
          bg: 'background.default',
          fontSize: 'sm'
        },
        '.chakra-button': {
          bg: 'primary'
        },
        input: {
          borderRadius: 'sm',
          borderColor: 'primary',
          bg: 'background.default'
        },
        textarea: {
          borderRadius: 'sm',
          borderColor: 'primary',
          bg: 'background.default'
        },
        '.rdrMonthPicker select': {
          bg: 'background.default',
          color: 'highlight',
          fontSize: 'sm',
          lineHeight: '12px'
        },
        '.rdrYearPicker select': {
          bg: 'background.default',
          color: 'highlight',
          fontSize: 'sm',
          lineHeight: '15px'
        },
        '.rdrCalendarWrapper': {
          bg: 'background.default',
          color: 'text.default'
        },
        '.rdrDayNumber span': {
          color: 'highlight'
        },
        '.chakra-checkbox__control': {
          transition: 'all 0.15s ease-in',
          bg: 'background.default',
          color: 'primary',
          _hover: {
            color: 'white'
          }
        }
      }}
      {...props}
    />
  )
}

export default FormWrapperCustom
